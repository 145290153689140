import React, { useState, useEffect, useCallback } from "react"
import { SearchTemplateContainer, SearchResultsGrid } from "./style"
import { MainColumn } from "UI/MainColumn"
import { useDidMountEffect } from "helpers/useDidMountEffect"
import algoliaIndex from "./algoliaIndex"
import { Row, Col } from "UI/Grid"
import { Pagination } from "UI/Pagination"
import { Spacer } from "UI"
import { ExtendedTeaser } from "components/Project/LatestResources/style"
import { useStaticQuery, graphql } from "gatsby"
import { Filters } from "UI"

const SearchTemplate = () => {
  const data = useStaticQuery(
    graphql`
      {
        resources: allWpPost {
          nodes {
            title
            uri
            featuredImage {
              node {
                sourceUrl
                srcSet
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    `
  )

  const categories = data.resources.nodes
    .reduce((acc, curr) => {
      curr.categories.nodes.forEach((category) => {
        if (!acc.includes(category.name)) {
          acc.push(category.name)
        }
      })
      return acc
    }, [])
    .sort()
  const filters = categories.map((category) => ({
    value: category,
    label: category.toUpperCase(),
  }))
  const allFilters = [{ value: null, label: "All" }, ...filters]

  const urlParams =
    typeof window !== `undefined` && new URLSearchParams(window.location.search)
  const filterParam =
    typeof window !== `undefined` && urlParams && urlParams?.get("f")

  const initialPosts = data.resources.nodes

  const [filter, setFilter] = useState(filterParam)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPosts, setCurrentPosts] = useState(initialPosts)

  const [totalPosts, setTotalPosts] = useState(initialPosts.length)
  const [limitFirstPosts, setLimitFirstPosts] = useState(100)
  const postsPerPage = 9

  const findResults = useCallback(() => {
    algoliaIndex
      .search("", {
        page: currentPage - 1,
        hitsPerPage: postsPerPage,
        facetFilters: [
          filter ? `categories: ${filter.replace("&", "&amp;")}` : "",
        ],
      })
      .then((data) => {
        setCurrentPosts(data.hits)
        setTotalPosts(data.nbHits)
      })
  }, [currentPage, , filter])

  useDidMountEffect(() => {
    findResults()
  }, [findResults])

  useEffect(() => {
    if (filter) {
      findResults()
    }
  }, [])

  useEffect(() => {
    setLimitFirstPosts(9)
  }, [])

  return (
    <SearchTemplateContainer>
      <Filters
        filter={filter}
        setFilter={setFilter}
        setCurrentPage={setCurrentPage}
        filters={allFilters}
      />

      <MainColumn>
        <Spacer height={[20, 20, 60]} />

        {currentPosts && (
          <SearchResultsGrid>
            <Row xsGap={0} smGap={20} smRowGap={10} mdGap={40} mdRowGap={28}>
              {currentPosts.slice(0, limitFirstPosts).map((item, index) => {
                const image = item?.featuredImage?.node || item.featuredImage
                const link = item.uri || item.link
                const tag = item.categories?.nodes
                  ? item.categories?.nodes[0]?.name
                  : item?.categories[0]

                return (
                  <Col xs={12} sm={6} md={4} key={index}>
                    <ExtendedTeaser
                      key={index}
                      title={item.title}
                      tag={tag}
                      link={link}
                      img={{ ...image, aspectRatio: 1.5 }}
                    />
                  </Col>
                )
              })}
            </Row>
          </SearchResultsGrid>
        )}

        <Spacer height={[null, 25, 30]} />
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          maxNumbersVisible={5}
          totalPosts={totalPosts}
          postsPerPage={postsPerPage}
          scrollId="header"
        />
      </MainColumn>
    </SearchTemplateContainer>
  )
}

export default SearchTemplate
