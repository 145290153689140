import styled from "styled-components"
// import { device } from "breakpoints"
import { Teaser } from "UI/Teaser"
import { Grid } from "UI/Grid"

export const SearchTemplateContainer = styled.div`
  position: relative;
  z-index: 30;
`

export const SearchResultTeaser = styled(Teaser)``

export const SearchResultsGrid = styled(Grid)`
  /* border: 2px dashed teal; */
`
