import React, { useState, useEffect, useCallback } from "react"
import {
  PaginationContainer,
  PageNumberList,
  Number,
  PreviousButton,
  NextButton,
  ListItem,
} from "./style.js"
import { animateScroll as scroll } from "react-scroll"
import PageNumber from "./PageNumber"
import history from "./history"
import { Icon } from "UI"
//! this means that the prop is REQUIRED for the component to work

const Pagination = ({
  currentPage, //! page you are currently on (number)
  setCurrentPage, //! sets the next page (function)
  maxNumbersVisible, //! max number of pages shown in the pagination list (number)
  totalPosts, //! total number of matching posts (number)
  postsPerPage, //! number of posts you wish to display on each page (number)
  /////////////////////////////////////////////
  scrollId, // id of element you want to scroll to on pagination (string)
  topOfElement, // scrolls to the top of the elemenn (boolean, default:false)
  filters, //to allow storage of filters history in back pagination
  setFilters,
  setQuery, //to allow storage of query history in back pagination
  pushToHistory, //pass function for pagination events to hook into history
}) => {
  const pageNumbers = []
  const lastPage = Math.ceil(totalPosts / postsPerPage)

  const [firstNumberVisible, setFirstNumberVisible] = useState(1)

  for (
    let i = firstNumberVisible;
    i <= firstNumberVisible + (maxNumbersVisible - 1) && i <= lastPage;
    i++
  ) {
    pageNumbers.push(i)
  }

  const skipForwards = () => {
    pagination(firstNumberVisible + maxNumbersVisible)
  }

  const skipBackwards = () => {
    pagination(firstNumberVisible - 1)
  }

  const scrollToTop = () => {
    const element = document.getElementById(scrollId)

    scroll.scrollTo(
      topOfElement
        ? element.offsetTop
        : element.offsetTop + element.clientHeight,
      { duration: 0, ignoreCancelEvents: true, smooth: "easeOutCubic" }
    )
  }

  // const setPaginationGroup = (page) => {
  //   setFirstNumberVisible(
  //     (Math.floor((page - 1) / maxNumbersVisible) + 1) * maxNumbersVisible -
  //       (maxNumbersVisible - 1)
  //   )
  // }

  const setPaginationGroup = useCallback(() => {
    setFirstNumberVisible(
      (Math.floor((currentPage - 1) / maxNumbersVisible) + 1) *
        maxNumbersVisible -
        (maxNumbersVisible - 1)
    )
  }, [currentPage, maxNumbersVisible, setFirstNumberVisible])

  const pagination = (page) => {
    if (scrollId) {
      scrollToTop()
    }
    setPaginationGroup(page)
    setCurrentPage(page)
    pushToHistory && pushToHistory(page, filters)
  }

  useEffect(() => {
    setPaginationGroup()
  }, [setPaginationGroup])

  //handle back & forwards
  useEffect(() => {
    const popListener = history.listen(({ location, action }) => {
      if (action === "POP") {
        const page = location?.state?.page || 1

        setCurrentPage(page)
        setPaginationGroup(page)
        setQuery && setQuery(location?.state?.query || "")
        setFilters && setFilters(location?.state?.filters || [])
      }
    })
    return function cleanUp() {
      popListener()
    }
  }, [setCurrentPage, setFilters, setPaginationGroup, setQuery])

  return (
    <PaginationContainer
      hide={totalPosts <= postsPerPage}
      className="container"
      noResults={totalPosts === 0}
    >
      <PreviousButton
        disabled={currentPage === 1}
        onClick={
          currentPage === pageNumbers[0]
            ? () => skipBackwards()
            : () => pagination(currentPage - 1)
        }
        className="button"
      >
        <Icon
          name="ll-arrow"
          width={["16px", null, "11px"]}
          color="#CED0D2"
          transform="scale(-1)"
        />
      </PreviousButton>

      <PageNumberList>
        <ListItem hidden={firstNumberVisible === 1}>
          <Number onClick={() => skipBackwards()}>...</Number>
        </ListItem>

        {pageNumbers.map((pageNumber) => {
          return (
            <PageNumber
              key={pageNumber}
              pageNumber={pageNumber}
              currentPage={currentPage}
              pagination={pagination}
            />
          )
        })}

        <ListItem
          hidden={firstNumberVisible >= lastPage - maxNumbersVisible + 1}
        >
          <Number onClick={() => skipForwards()}>...</Number>
        </ListItem>
      </PageNumberList>

      <NextButton
        className="button"
        disabled={totalPosts === 0 || currentPage === lastPage}
        onClick={
          currentPage === pageNumbers[pageNumbers.length - 1]
            ? () => skipForwards()
            : () => pagination((currentPage * 10) / 10 + 1)
        }
      >
        <Icon name="ll-arrow" width={["16px", null, "11px"]} color="#FD8211" />
      </NextButton>
    </PaginationContainer>
  )
}

export default Pagination
