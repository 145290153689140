import { useEffect, useRef } from "react"
//this is basically a useEffect, but doesnt get run on first mount

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
  }, deps)
}
