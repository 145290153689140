import styled from "styled-components"
import { device } from "breakpoints"

export const PaginationContainer = styled.div`
  display: flex;
  opacity: ${(props) => (props.noResults || props.hide ? "0" : "1")};
  transition: 140ms;
  align-items: center;
  border-top: 1px solid #ced0d2;

  justify-content: center;
  padding-top: 30px;
  margin-bottom: 50px;
  @media ${device.desktop} {
    padding-top: 40px;
    justify-content: flex-end;
    margin-bottom: -20px;
  }
`
export const PageNumberList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0 15px;
`

export const ListItem = styled.li`
  display: ${(props) => (props.hidden ? "none" : "block")};
  padding: 0 7px;
  position: relative;

  @media ${device.desktop} {
    padding: 0 5px;
  }
`

export const Number = styled.button`
  font-family: ${(props) => props.theme.headerFont};
  outline: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  color: ${(props) => (props.active ? "white" : "#1870B0")};
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.active ? props.theme.primaryColor : "none")};
  border: none;
  border-radius: 50%;
  transition: 80ms;
  font-weight: bold;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  :hover {
    background: ${(props) => props.active && props.theme.colors.darkBlue};
    color: ${(props) => !props.active && props.theme.primaryColor};
  }
`

export const Skip = styled(Number)`
  height: auto;
  width: auto;
`

export const NextButton = styled.button`
  outline: none;
  cursor: pointer;
  border: none;
  pointer-events: ${(props) => props.disabled && "none"};
  /* opacity:1; */
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  transition: 90ms;
  margin-left: 5px;
  position: relative;
  @media ${device.desktop} {
    margin-left: 0;
    margin-right: 0;
  }
  :hover {
    opacity: 0.7;
  }

  &:before {
    content: "";

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 280%;
    height: 200%;
  }
`

export const PreviousButton = styled(NextButton)`
  margin-left: 0px;
  margin-right: 5px;
`
