import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { graphql } from "gatsby"
import { SearchTemplate } from "../../components/Templates/SearchTemplate"

const ResourceIndex = ({ data }) => {
  const { seo, featuredImage } = data.page

  return (
    <Layout variant="internal" pageContext={data?.page} key={data.page.uri}>
      <SEO
        title={seo.title}
        image={featuredImage?.node?.sourceUrl}
        description={seo.metaDesc}
      />

      <SearchTemplate />
    </Layout>
  )
}

export default ResourceIndex

export const pageQuery = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      seo {
        title
        metaDesc
      }
      headerSettings {
        customPageTitle
      }
      featuredImage {
        node {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`
